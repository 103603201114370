import React from "react"

export const BrandIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="92.193"
      viewBox="0 0 100 92.193"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...props}
    >
      <path
        d="M-6689.7,2910.046l-25.912-26.62a3.809,3.809,0,0,1,.076-5.385,3.806,3.806,0,0,1,5.385.076l23.211,23.852,35.128-35.129a3.806,3.806,0,0,1,.413-.49,23.06,23.06,0,0,0,6.813-16.425,23.255,23.255,0,0,0-6.807-16.45,23.244,23.244,0,0,0-16.447-6.811,23.056,23.056,0,0,0-16.425,6.812,3.805,3.805,0,0,1-3.52,1.029,3.739,3.739,0,0,1-1.958-1.029,23.06,23.06,0,0,0-16.417-6.812,23.246,23.246,0,0,0-23.228,23.219,23.522,23.522,0,0,0,.768,5.909,3.8,3.8,0,0,1-2.708,4.65,3.8,3.8,0,0,1-2.889-.4,3.8,3.8,0,0,1-1.762-2.323,30.911,30.911,0,0,1-1.021-7.883,30.872,30.872,0,0,1,30.84-30.84,30.6,30.6,0,0,1,19.159,6.676,30.594,30.594,0,0,1,19.16-6.676,30.875,30.875,0,0,1,30.841,30.84,30.62,30.62,0,0,1-8.566,21.337,3.291,3.291,0,0,1-.432.524l-38.3,38.3a3.812,3.812,0,0,1-2.692,1.114l-.01.076A3.81,3.81,0,0,1-6689.7,2910.046Zm-5.006-40a6.321,6.321,0,0,1,.279-1.865l-9.3-9.3a6.334,6.334,0,0,1-2.326.441l.017.033a6.336,6.336,0,0,1-2.118-.363l-9.23,9.229a6.326,6.326,0,0,1,.266,1.82,6.338,6.338,0,0,1-6.339,6.339,6.339,6.339,0,0,1-6.338-6.339,6.339,6.339,0,0,1,6.338-6.339,6.315,6.315,0,0,1,2.37.458l9.063-9.063a6.3,6.3,0,0,1-.351-2.081,6.35,6.35,0,0,1,2.288-4.874,6.345,6.345,0,0,1,5.208-1.363,6.371,6.371,0,0,1,5.064,5.063,6.325,6.325,0,0,1-.178,3.005l9.295,9.3a6.305,6.305,0,0,1,2.328-.442,6.316,6.316,0,0,1,2.149.375l13.595-13.6a9.02,9.02,0,0,1-.94-4.021,9.056,9.056,0,0,1,9.058-9.048,9.055,9.055,0,0,1,9.056,9.048,9.047,9.047,0,0,1-9.056,9.048,9.027,9.027,0,0,1-4.3-1.079L-6682.368,2868a6.353,6.353,0,0,1,.339,2.05,6.339,6.339,0,0,1-6.338,6.339A6.342,6.342,0,0,1-6694.707,2870.048Z"
        transform="translate(6737 -2819.001)"
      />
    </svg>
  )
}
