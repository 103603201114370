import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../components/hero/hero"
import Tool from "../components/tool/tool"
import Services from "../components/services/services"
import Offer from "../components/offer/offer"
import Contact from "../components/contact/contact"
import PageLink from "../components/page-link/page-link"

const IndexPage = ({ location }) => (
  <Layout hasHero headerStyle="negative">
    <Seo title="Home" />
    <Hero />
    <PageLink to="/#tool" />
    <Tool id="tool" />
    <PageLink to="/#leistungen" />
    <Services id="leistungen" marginTop marginBottom="m" />
    <PageLink to="/#angebot" />
    <Offer id="angebot" marginBottom="m" />
    <PageLink to="/#kontakt" />
    <Contact id="kontakt" marginTop="m" />
  </Layout>
)

export default IndexPage
