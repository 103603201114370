import { styled, theme } from "twin.macro"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { buttonStyle } from "../../styles/form"

export const StyledButtonLink = styled(AnchorLink)`
  ${buttonStyle}
  margin-top: 15px;

  @media (min-width: ${theme`screens.sm`}) {
    margin-top: 22px;
  }

  @media (min-width: ${theme`screens.md`}) {
    margin-top: 30px;
  }
`
