import tw, { styled, theme } from "twin.macro"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { BrandIcon } from "../icons/icons"
import { buttonStyle } from "../../styles/form"
import BackgroundImage from "gatsby-background-image"

export const Wrapper = styled.section`
  ${tw`
    bg-primary
    relative
    transition-opacity
    duration-200
    opacity-0
    z-0
  `}

  ${props => (props.loaded ? tw`opacity-100` : "")}
`
export const HeroIcon = styled(BrandIcon)`
  ${tw`h-auto`}
  margin-top: 7px;
  width: 50px;

  @media (min-width: ${theme`screens.sm`}) {
    margin-top: 10px;
    width: 70px;
  }

  @media (min-width: ${theme`screens.md`}) {
    width: 80px;
  }

  @media (min-width: ${theme`screens.md`}) {
    width: 110px;
  }
`

export const HeroContent = styled.div`
  ${tw`
    absolute
    flex
    flex-col
    justify-center
    items-center
    inset-0
    text-center
    text-primary
  `}

  padding-top: 40px;

  &::before {
    ${tw`
      absolute
      bg-gray-dark
      inset-0
    `}
    content: "";
    mix-blend-mode: multiply;
    z-index: -1;
  }

  &::after {
    ${tw`
      absolute
      top-0
      left-0
      w-full
      bg-gradient-to-b from-gray-dark
      opacity-50
    `}
    content: "";
    height: 30%;
    min-height: 130px;
    mix-blend-mode: multiply;
    z-index: -1;
  }
`

export const HeroBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 354px;

  @media (min-width: ${theme`screens.sm`}) {
    height: 450px;
  }

  @media (min-width: ${theme`screens.md`}) {
    height: 500px;
  }

  @media (min-width: ${theme`screens.md`}) {
    height: 550px;
  }
`
export const HeroButton = styled(AnchorLink)`
  ${buttonStyle}
  margin-top: 15px;

  @media (min-width: ${theme`screens.sm`}) {
    margin-top: 22px;
  }

  @media (min-width: ${theme`screens.md`}) {
    margin-top: 30px;
  }
`
