import React from "react"
import "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import { PageSection, Header, Grid } from "../../styles/container"
import { H2, H3 } from "../../styles/headings"
import { Bullets } from "../../styles/text"
import { StyledButtonLink } from "./tool.styles"

const Tool = props => {
  const data = useStaticQuery(graphql`
    {
      text: airtableTexts(data: { id: { eq: "tool" } }) {
        data {
          id
          headline
          subline
          text1 {
            childMdx {
              rawBody
            }
          }
          text2 {
            childMdx {
              rawBody
            }
          }
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div {...props}>
      <PageSection boxed fluid paddingTop>
        <Header marginBottom="xs">
          <H2>{data.text.data.headline}</H2>
          <H3>{data.text.data.subline}</H3>
        </Header>

        <Grid columns={2} breakPoint="md">
          <div>
            <ReactMarkdown>
              {data.text.data.text1.childMdx.rawBody}
            </ReactMarkdown>
            <StyledButtonLink to="/#angebot">
              Beispiel-Report anfragen
            </StyledButtonLink>
          </div>
          <div>
            <Bullets>
              <ReactMarkdown>
                {data.text.data.text2.childMdx.rawBody}
              </ReactMarkdown>
            </Bullets>
            <GatsbyImage
              image={getImage(data.text.data.image.localFiles[0])}
              alt={data.text.data.headline}
            />
          </div>
        </Grid>
      </PageSection>
    </div>
  )
}

export default Tool
