import tw, { styled } from "twin.macro"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const StyledLink = styled(AnchorLink)`
  ${tw`
    block
    mx-auto
    
  `}

  heigh: 50px;
  margin-top: -25px;
  width: 50px;
`
