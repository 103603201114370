import React, { useRef, useState } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Wrapper,
  SliderImage,
  SliderNav,
  SliderPagination,
  SliderPrevNextBtn,
  SliderTitle,
} from "./slider.styles"
import { SimpleArrow } from "../arrow/arrow"
import "swiper/swiper-bundle.min.css"

SwiperCore.use([Navigation, Pagination])

const Slider = ({ images }) => {
  const paginationRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [title, setTitle] = useState("")
  const [showTtitle, setShowTitle] = useState(false)

  return (
    <Wrapper>
      <Swiper
        pagination={{ el: paginationRef.current, clickable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={swiper => {
          swiper.params.pagination.el = paginationRef.current
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        onSlideChangeTransitionStart={() => {
          setShowTitle(false)
        }}
        onSlideChangeTransitionEnd={swiper => {
          const { title } = images[swiper.realIndex]
          setTitle(title)
          if (title) {
            setShowTitle(true)
          }
        }}
        loop={true}
      >
        <SliderNav>
          <SliderPrevNextBtn ref={navigationPrevRef}>
            <SimpleArrow direction="left" />
          </SliderPrevNextBtn>
          <SliderPagination ref={paginationRef} />
          <SliderPrevNextBtn ref={navigationNextRef}>
            <SimpleArrow direction="right" />
          </SliderPrevNextBtn>
        </SliderNav>

        {images.map(({ image, title }, index) => (
          <SwiperSlide key={index}>
            <SliderImage image={image} alt={title} objectFit="contain" />
          </SwiperSlide>
        ))}
        <SliderTitle show={showTtitle}>{title}</SliderTitle>
      </Swiper>
    </Wrapper>
  )
}

export default Slider
