import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import "twin.macro"
import ReactMarkdown from "react-markdown"
import { getImage } from "gatsby-plugin-image"

import { PageSection, Container, Header, Grid } from "../../styles/container"
import { H2, H3 } from "../../styles/headings"
import { Card, NewBadge } from "./services.styles"
import Slider from "../slider/slider"

const query = graphql`
  {
    allAirtableServices(sort: { fields: rowIndex, order: ASC }) {
      nodes {
        data {
          badge
          name
          public
          highlight
          description {
            childMdx {
              rawBody
            }
          }
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        id
      }
    }
  }
`

const Services = props => {
  const {
    allAirtableServices: { nodes: services },
  } = useStaticQuery(query)

  const sliderImages = services.reduce((acc, service) => {
    acc.push({
      image: getImage(service.data.image.localFiles[0]),
      title: service.data.name,
    })
    return acc
  }, [])

  return (
    <PageSection {...props}>
      <Slider images={sliderImages} />
      <Container fluid boxed marginTop>
        <Header center marginBottom="s">
          <H2> Leistungen</H2>
        </Header>
        <Grid columns={2} breakPoint="md">
          {services.map(
            ({ id, data: { name, description, badge, highlight } }) => (
              <Card key={id} highlight={highlight}>
                <H3 headingStyle="h4">{name}</H3>
                {badge === "Neu" ? <NewBadge>{badge}</NewBadge> : ""}
                <ReactMarkdown>{description.childMdx.rawBody}</ReactMarkdown>
              </Card>
            )
          )}
        </Grid>
      </Container>
    </PageSection>
  )
}

export default Services
