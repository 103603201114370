import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import { H1 } from "../../styles/headings"

import {
  Wrapper,
  HeroBackgroundImage,
  HeroContent,
  HeroIcon,
  HeroButton,
} from "./hero.styles"

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const Hero = props => {
  const [loaded, setLoaded] = useState(false)
  const { heroImage } = useStaticQuery(query)
  const image = getImage(heroImage)

  const bgImage = convertToBgImage(image)

  return (
    <Wrapper loaded={loaded} {...props}>
      <HeroBackgroundImage
        {...bgImage}
        preserveStackingContext
        fadeIn={false}
        durationFadeIn={2200}
        onLoad={() => setLoaded(true)}
      >
        <HeroContent>
          <H1>
            More Insights.
            <br />
            More Sales.
          </H1>
          <HeroIcon />
          <HeroButton to="/#angebot">Beispiel-Report anfragen</HeroButton>
        </HeroContent>
      </HeroBackgroundImage>
    </Wrapper>
  )
}

export default Hero
