import React from "react"

import { StyledLink } from "./page-link.styles"
import Arrow from "../arrow/arrow"

const PageLink = ({ to }) => {
  return (
    <StyledLink to={to} offset={-75} duration={500}>
      <Arrow direction="down" />
    </StyledLink>
  )
}

export default PageLink
