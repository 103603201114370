import React from "react"
import { theme } from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import { H2 } from "../../styles/headings"
import { ContactWrapper, ContactText, ContactImage } from "./contact.styles"

const Contact = props => {
  const data = useStaticQuery(graphql`
    {
      text: airtableTexts(data: { id: { eq: "kontakt" } }) {
        data {
          headline
          text1 {
            childMdx {
              rawBody
            }
          }
          desktopImage: image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          mobileImage: image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.5
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  const {
    headline,
    text1: {
      childMdx: { rawBody: text },
    },
    desktopImage,
    mobileImage,
  } = data.text.data

  const images = withArtDirection(getImage(desktopImage?.localFiles[0]), [
    {
      media: `(max-width: ${theme`screens.md`})`,
      image: getImage(mobileImage?.localFiles[0]),
    },
  ])

  return (
    <ContactWrapper {...props}>
      <ContactImage image={images} alt={headline} />
      <ContactText>
        {headline && <H2>{headline}</H2>}
        {text && <ReactMarkdown>{text}</ReactMarkdown>}
      </ContactText>
    </ContactWrapper>
  )
}

export default Contact
