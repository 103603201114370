import tw, { styled, theme } from "twin.macro"
import { Article } from "../../styles/container"

const normalCard = tw`
  bg-gray-light
`

const highlighCard = tw`
  bg-primary
  text-white
`

export const Card = styled(Article)`
  ${tw`
    rounded-xl
    relative
  `}
  padding: 20px 15px;
  ${props => (props.highlight ? highlighCard : normalCard)}

  @media (min-width: ${theme`screens.sm`}) {
    padding: 20px;
  }
  @media (min-width: ${theme`screens.md`}) {
    padding: 27px;
  }
  @media (min-width: ${theme`screens.lg`}) {
    padding: 30px;
  }
`

export const NewBadge = styled.span`
  ${tw`
    bg-action
    flex
    flex-col
    font-extra-bold
    uppercase
    items-center
    justify-center
    absolute
    rounded-full
  `}

  height: 4rem;
  right: -1rem;
  top: -1rem;
  width: 4rem;
`
