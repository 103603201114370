import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Container, Grid } from "../../styles/container"
import { H2 } from "../../styles/headings"
import { P } from "../../styles/text"
import {
  FormText,
  FormTextArea,
  FormSelect,
  FormRow,
  FormRowCheckbox,
  FormCheckboxWrap,
  FormCheckbox,
  FormCheckboxLabel,
} from "../../styles/form"
import { Button } from "../../styles/form"

const Offer = props => {
  const data = useStaticQuery(graphql`
    {
      text: airtableTexts(data: { id: { eq: "angebot" } }) {
        data {
          headline
          text1 {
            childMdx {
              rawBody
            }
          }
        }
      }
    }
  `)

  const {
    headline,
    text1: {
      childMdx: { rawBody: text },
    },
  } = data.text.data
  return (
    <Container fluid boxed paddingTop {...props}>
      <Grid columns={2} breakPoint="lg">
        <div>
          {headline && <H2 headingStyle="h1">{headline}</H2>}
          {text && <ReactMarkdown>{text}</ReactMarkdown>}
        </div>
        <div>
          <form
            data-netlify="true"
            name="Contact"
            method="POST"
            action="/kontakt/danke"
          >
            <input type="hidden" name="form-name" value="Contact" />
            <FormRow>
              <FormSelect name="Anrede">
                <option value="">Anrede</option>
                <option value="Frau">Frau</option>
                <option value="Herr">Herr</option>
              </FormSelect>
            </FormRow>
            <FormRow>
              <FormText
                type="text"
                placeholder="Vorname*"
                name="Vorname"
                required
              />
            </FormRow>
            <FormRow>
              <FormText
                type="text"
                placeholder="Nachame *"
                name="Nachname"
                required
              />
            </FormRow>
            <FormRow>
              <FormText
                type="email"
                placeholder="E-Mail *"
                name="E-Mail"
                required
              />
            </FormRow>
            <FormRow>
              <FormTextArea placeholder="Nachricht" name="Nachricht" />
            </FormRow>
            <FormRowCheckbox>
              <FormCheckboxWrap>
                <FormCheckbox
                  id="cb-beispiel-report"
                  name="Beispiel-Report"
                  type="checkbox"
                />
              </FormCheckboxWrap>
              <FormCheckboxLabel for="cb-beispiel-report">
                JA, ich möchte ein Beispiel-Reporting vom Health-Inspector
                erhalten.
              </FormCheckboxLabel>
            </FormRowCheckbox>
            <FormRowCheckbox>
              <FormCheckboxWrap>
                <FormCheckbox
                  id="cb-datenschutz"
                  name="Datenschutz"
                  type="checkbox"
                  required
                />
              </FormCheckboxWrap>
              <FormCheckboxLabel for="cb-datenschutz">
                JA, ich stimme den{" "}
                <a
                  href="/datenschutz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzbestimmungen
                </a>{" "}
                zu *.
              </FormCheckboxLabel>
            </FormRowCheckbox>
            <FormRow>
              <P size="s">* Pflichtfelder</P>
            </FormRow>
            <FormRow>
              <Button type="submit">Abschicken</Button>
            </FormRow>
          </form>
        </div>
      </Grid>
    </Container>
  )
}

export default Offer
