import tw, { styled, theme } from "twin.macro"
import { Section } from "../../styles/container"
import { GatsbyImage } from "gatsby-plugin-image"
import { spacingTop, spacingBottom } from "../../styles/layout"

export const ContactWrapper = tw(Section)`
  relative
  overflow-hidden
`

export const ContactText = styled.div`
  ${spacingBottom("margin", "s")}
  ${spacingTop("margin", "s")}
  ${tw`
    mx-outer-margin-xs
    md:(absolute
      mx-0
      flex
      flex-col
      left-1/2
      top-0
      h-full
      justify-center
      my-0
    )
  `}

  strong {
    ${tw`
      uppercase
      font-bold
    `}
  }

  em {
    ${tw`
      text-primary
      not-italic
    `}
  }
`

export const ContactImage = styled(GatsbyImage)`
  height: 0;
  padding-top: ${(480 / 728) * 100}%;

  @media (min-width: ${theme`screens.md`}) {
    height: auto;
    padding-top: 0;
  }

  > div {
    &:first-child {
      display: none !important;

      @media (min-width: ${theme`screens.md`}) {
        display: block !important;
      }
    }
  }
`
