import tw, { styled, theme } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
  ${tw`
    relative
    overflow-hidden
    mx-auto
    h-0
    w-full
  `}

  padding-top: 36%;
  --swiper-theme-color: ${theme`colors.primary`};

  &::after {
    content: "";
    height: 25%;
    mix-blend-mode: multiply;

    ${tw`
      absolute
      bottom-0
      left-0
      w-full
      bg-gradient-to-t from-gray-dark
      opacity-20
      pointer-events-none
      z-10
    `};
  }
  & .swiper-container {
    ${tw`
      absolute
      inset-0
      h-full
      w-full
  `}
  }
`
export const Slide = styled.div`
  ${tw`
    w-full
  `}
`

export const SliderImage = styled(GatsbyImage)`
  ${tw`
    h-full
    
  `}
`
export const SliderNav = styled.div`
  ${tw`
    absolute
    flex
    inset-y-0
    inset-x-outer-margin-xs
    lg:inset-x-outer-margin-sm
    xl:inset-x-outer-margin-md
    justify-between
    z-20
  `}

  & .swiper-pagination-bullet {
    ${tw`
      bg-transparent
      border
      border-white
      opacity-100
    `}
  }

  & .swiper-pagination-bullet-active {
    ${tw`
      bg-white
    `}
  }
`

export const SliderPrevNextBtn = styled.div`
  ${tw`
    self-center
    text-primary
    opacity-50
    transition-opacity
    duration-300
    hocus:opacity-100
  `}
  height: 25px;

  @media (min-width: ${theme`screens.sm`}) {
    height: 30px;
  }

  @media (min-width: ${theme`screens.md`}) {
    height: 35px;
  }

  @media (min-width: ${theme`screens.lg`}) {
    height: 40px;
  }
`

export const SliderPagination = styled.div`
  ${tw`
    flex
    self-end
  `}
  gap: 8px;
  height: 20px;
`
export const SliderTitle = styled.span`
  ${tw`
    absolute
    left-1/2
    text-center
    transform
    -translate-x-1/2
    font-bold
    text-primary
    text-gray-dark
    bg-white
    bg-opacity-90
    rounded-md
    transition-opacity
    duration-200
    z-30
    shadow-md
    ring-primary
    ring-offset-primary
  `}
  backdrop-filter: blur(3px);
  bottom: 10%;
  padding: 1px 12px;
  ${props => (props.show ? tw`opacity-100` : tw`opacity-0`)}
`
